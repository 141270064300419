/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { faker } from "@faker-js/faker";
import { useTheme, styled } from "@mui/material/styles";
import { Box, Grid, Paper } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CheckBoxImg = (props) => {
  const [arrayIds, setArrayIds] = useState([]);
  const [respuestas, setRespuestas] = useState([]);
  const theme = useTheme();
  const cleanAllCells = (arrayRespuestas) => {
    arrayIds.forEach((ele) => {
      if (!arrayRespuestas.find((element) => element === ele)) {
        document.getElementById(ele).style.backgroundColor = "gray";
      } else {
        document.getElementById(ele).style.backgroundColor =
          theme.palette.primary.dark;
      }
    });
  };

  const handleClick = (codOpcion) => {
    let arrayRespuestas = [...respuestas];
    //desmarcar
    if (arrayRespuestas.find((element) => element === codOpcion)) {
      const index = arrayRespuestas.indexOf(codOpcion);
      if (index > -1) {
        arrayRespuestas.splice(index, 1);
        //Elimino la respuesta de las respuestas seleccionadas
        saveResponse(null, codOpcion);
      }
    } else {
      //marcar
      arrayRespuestas.push(codOpcion);
      saveResponse(codOpcion, null);
    }
    setRespuestas(arrayRespuestas);
    cleanAllCells(arrayRespuestas);
    // arrayRespuestas.forEach((ele) => {
    //   saveResponse(ele, null);
    // });
  };

  const saveResponse = async (codOpcion, respuestaEliminar) => {
    try {
      let tiempoRespuesta = props.tiempoLimite - props.seconds;
      if (props.tiempoLimite < 0) {
        tiempoRespuesta = parseInt((Date.now() - props.timeStartPrueba) / 1000);
      }

      let pregunta = props.pregunta;
      let respuesta = codOpcion;
      let textoInputRespuesta = null;

      props.updateRespuestasSeleccionadas(
        parseInt(pregunta),
        parseInt(respuesta),
        parseInt(tiempoRespuesta),
        null,
        null,
        true, //Multiple respuestas
        respuestaEliminar
      );
      props.updateSeguimiento(
        "Respuesta seleccionada",
        `Pregunta:${pregunta};Respuesta:${respuesta};TiempoRespuesta:${tiempoRespuesta};RespuestaAutomatica:${textoInputRespuesta}`
      );
      //console.log("Guardada la respuesta...");
    } catch (error) {
      console.log("Error al guardar la imagen...", error);
    }
  };

  useEffect(() => {
    let data = [];
    props.opciones.forEach((ele) => {
      data.push(ele.codOpcion);
    });
    setArrayIds(data);
  }, []);
  return (
    <div
      style={{
        padding: "1em",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container width={props.anchoPizarra}>
        {props.opciones &&
          props.opciones.map((element, index) => (
            <Grid
              key={element.codOpcion}
              id={element.codOpcion}
              onClick={(e) => handleClick(element.codOpcion)}
              item
              xs={props.columnItemSize}
              style={{
                padding: "10px",
                backgroundColor: "gray",
                //border: "1px solid black",
              }}
            >
              <img
                style={{ width: 50 }}
                //src={faker.image.food(null, null, true)}
                src={element.texto}
              />
            </Grid>
          ))}
      </Grid>
    </div>
  );
};

export default CheckBoxImg;

{
  /*<div style={{ padding: "1em" }}>
      <table id="mytable" border={1} align="center" cellPadding={15}>
        <tbody>
          {jsonArray &&
            jsonArray.map((item, index) => (
              <tr key={index}>
                {item &&
                  item.map((item2) => (
                    <td
                      key={item2.id}
                      id={item2.id}
                      onClick={(e) => handleClick(item2.id)}
                    >
                      <img
                        src={faker.image.nature(null, null, true)}
                        width="150"
                      />
                    </td>
                  ))}
              </tr>
            ))}
        </tbody>
      </table>
                  </div>*/
}
