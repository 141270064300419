import React, { useState, useEffect } from "react";
import {
    Grid,
    FormHelperText,
    InputLabel,
    FormControl,
    Box,
    MenuItem,
    Button,
    Divider,
    Container,
    Typography,
    AppBar,
    Toolbar,
    Card,
    CardContent,
    TextField,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import SaveIcon from '@mui/icons-material/Save';
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Formik } from "formik";
import testService from "services/test.services";
import moment from "moment";
import * as Yup from "yup";
import SelectCustom from "components/ui/Selects/SelectCustom";

import CalificarRenderPregunta from "./../components/includes/CalificarRenderPregunta";

import {
    showAlertError,
    showAlertInfo,
    showAlertSuccess
} from "utils/alert";
// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const initialValues = {
    fechaInicio: moment(),
    fechaFin: moment(),
    colegio: "",
    grado: "",
    grupo: "",
    tipoPrueba: "",
    prueba: "",
    codEstudiante: "",
};

const estudianteInitalValue = { "codigo": "ND", "nombre": "ND" };
const pruebaInitialValue = { "nombre": "ND" };

const formSchema = Yup.object().shape({
    fechaInicio: Yup.date().required("Campo Requerido"),
    fechaFin: Yup.date().required("Campo Requerido")
});

const CalificarTestForm = ({ setOpenLoading }) => {
    const [colegios, setColegios] = useState([]);
    const [grados, setGrados] = useState([]);
    const [grupos, setGrupos] = useState([]);
    const [tipoPruebas, setTipoPruebas] = useState([]);

    const [estudiante, setEstudiante] = useState(estudianteInitalValue);
    const [prueba, setPrueba] = useState(pruebaInitialValue);
    const [evaluaciones, setEvaluaciones] = useState([]);
    const [respuestas, setRespuestas] = useState([]);

    async function getColegios() {
        try {
            var response = await testService.resultTest_GetColegios();
            if (response.status === 200) {
                setColegios(response.data.data);
            }
        } catch (error) {
            /*showAlert('error',
              '!Ha ocurrido un error!',
              'Al cargar los colegios');*/
        }
    }
    async function getGrados(data) {
        try {
            var response = await testService.resultTest_GetGrados(data);
            if (response.status === 200) {
                setGrados(response.data.data);
            }
        } catch (error) {
            /*showAlert('error',
              '!Ha ocurrido un error!',
              'Al cargar los colegios');*/
        }
    }
    async function getGrupos(data) {
        try {
            var response = await testService.resultTest_GetGrupos(data);
            if (response.status === 200) {
                setGrupos(response.data.data);
            }
        } catch (error) {
            /*showAlert('error',
              '!Ha ocurrido un error!',
              'Al cargar los colegios');*/
        }
    }
    async function getTipoPruebas() {
        try {
            var response = await testService.resultTest_GetTipoPruebas();
            if (response.status === 200) {
                setTipoPruebas(response.data.data);
            }
        } catch (error) {
            /*showAlert('error',
              '!Ha ocurrido un error!',
              'Al cargar los colegios');*/
        }
    }
    async function getEvaluacionesRegistradas(data, resetForm) {
        try {
            setOpenLoading(true);
            var response = await testService.calificar_GetPruebasEstudiantes(data);
            if (response.status === 200) {
                setOpenLoading(false);
                if (response.data.data.length > 0) {
                    setEvaluaciones(response.data.data);
                    setPrueba(pruebaInitialValue);
                    setEstudiante(estudianteInitalValue);
                    setRespuestas([]);

                } else {
                    showAlertInfo("No se encontraron datos");
                }
            }
        } catch (error) {
            setOpenLoading(false);
            showAlertError("No se encontraron datos");
        }
    }
    async function getPruebaSeleccionada(data, resetForm) {
        try {
            setOpenLoading(true);
            var response = await testService.calificar_GetPrueba(data);
            if (response.status === 200) {
                setOpenLoading(false);
                setEstudiante(response.data.data.estudiante);
                setPrueba(response.data.data.prueba);
                setRespuestas([]);
            }
        } catch (error) {
            setOpenLoading(false);
            showAlertError("No se encontraron datos");
        }
    }
    async function saveCalificaciones() {
        try {
            setOpenLoading(true);
            var response = await testService.calificar_updateCalificaciones({ "resultados": respuestas });
            console.log(response)
            if (response.status === 200) {
                setOpenLoading(false);
                setPrueba(pruebaInitialValue);
                setRespuestas([]);
                showAlertSuccess("Datos de calificaciones registradas correctamente")
            }
        } catch (error) {
            setOpenLoading(false);
        }

    }

    const itemGetRespuestaManual = (respuesta) => {
        let respManual = '';
        respuesta.forEach(element => {
            respManual = element.respuesta ? element.respuesta.manual : '';
        });
        return respManual;
    }

    const handleItemClick = (estudiante, prueba) => {
        getPruebaSeleccionada({
            estudiante: estudiante,
            evaluacion: prueba.id
        })
    };

    const renderTreeItemEstudiante = (item) => {
        const renderTreeItemPruebaWithItem = (child) => {
            return renderTreeItemPrueba(item, child);
        };

        return (
            <TreeItem
                key={item.codigo}
                nodeId={item.codigo}
                label={'(' + item.codigo + ') ' + item.nombre} >
                {item.pruebas && item.pruebas.map(child => renderTreeItemPrueba(item.id, child))}
            </TreeItem>
        );
    };
    const renderTreeItemPrueba = (estudiante, prueba) => (
        <TreeItem
            nodeId={prueba.id}
            label={`(${prueba.fecha}) ${prueba.nombre}`}
            onClick={() => handleItemClick(estudiante, prueba)} />
    );

    const handleTextFieldhange = (param) => (event) => {
        let pregunta = event.target.name;
        let respuestaManual = event.target.value;

        let dataValorCapt = {};
        let tempRespuestasSeleccionadas = respuestas;
        if (respuestaManual !== null) {
            dataValorCapt["evaluacion"] = prueba.id_eval;
            dataValorCapt["pregunta"] = pregunta;
            dataValorCapt["respuesta"] = respuestaManual;
        }

        let preguntaConRespuesta = false;
        tempRespuestasSeleccionadas.forEach((item) => {
            if (parseInt(item.pregunta) === parseInt(pregunta)) {
                preguntaConRespuesta = true;
                item.respuesta = respuestaManual;
            }
        });

        if (!preguntaConRespuesta) {
            tempRespuestasSeleccionadas.push({
                evaluacion: prueba.id_eval,
                pregunta: pregunta,
                respuesta: respuestaManual,
            });
        }
        setRespuestas(tempRespuestasSeleccionadas);
        console.log(respuestas)
    };

    const initForm = () => {
        getColegios();
        getTipoPruebas();
    };

    useEffect(() => {
        initForm();
    }, []);
    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={formSchema}
                validate={(values) => {
                    const errors = {};
                    if (
                        moment(values.fechaInicio).valueOf() >
                        moment(values.fechaFin).valueOf()
                    ) {
                        errors.fechaInicio = "No puede asignar";
                        errors.fechaFin = "No puede asignar";
                    }
                    if (values.colegio) {
                        let data = {
                            colegio: values.colegio,
                        };
                        getGrados(data);
                    }
                    if (values.grado) {
                        let data = {
                            colegio: values.colegio,
                            grado: values.grado,
                        };
                        getGrupos(data);
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    var data = null;
                    data = {
                        tipo: values.tipoPrueba
                    };
                    if (values.tipoPrueba) {
                        data.tipo = values.tipoPrueba;
                    }
                    if (values.grado) {
                        data.grado = values.grado;
                    }
                    if (values.grupo) {
                        data.grupo = values.grupo;
                    }
                    if (values.colegio) {
                        data.colegio = values.colegio;
                    }
                    getEvaluacionesRegistradas(data, resetForm);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    resetForm,
                    setFieldValue,
                }) => (
                    <Box
                        component="form"
                        noValidate
                        id="resutado-pruebas-form"
                        sx={{ mt: 1 }}
                    >
                        <Grid
                            container
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                bgcolor: "background.paper",
                                borderRadius: 1,
                            }}
                        >
                            {/* Colegios */}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={3}
                                sx={{ padding: "1em" }}
                            >
                                <FormControl
                                    fullWidth
                                    error={errors.colegio ? true : false}
                                >
                                    <InputLabel id="input-label-colegio">Colegios</InputLabel>
                                    <SelectCustom
                                        labelId="select-label-colegio"
                                        id="select-colegio"
                                        name="colegio"
                                        label="colegios"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.colegio}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                        {colegios &&
                                            colegios.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))}
                                    </SelectCustom>
                                    {errors.colegio && touched.colegio && (
                                        <FormHelperText>{errors.colegio}</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/* Grados */}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={2}
                                sx={{ padding: "1em" }}
                            >
                                <FormControl fullWidth error={errors.grado}>
                                    <InputLabel id="input-label-grado">Grados</InputLabel>
                                    <SelectCustom
                                        labelId="select-label-grado"
                                        id="select-grado"
                                        name="grado"
                                        label="grados"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.grado}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                        {grados &&
                                            grados.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))}
                                    </SelectCustom>
                                    {errors.grado && touched.grado && (
                                        <FormHelperText>Campo Requerido</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/* Grupos */}
                            <Grid
                                item
                                xs={12}
                                sm={12}
                                md={6}
                                lg={2}
                                sx={{ padding: "1em" }}
                            >
                                <FormControl fullWidth error={errors.grupo}>
                                    <InputLabel id="input-label-inst">Grupos</InputLabel>
                                    <SelectCustom
                                        labelId="select-label-grupo"
                                        id="select-grupo"
                                        name="grupo"
                                        label="grupos"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.grupo}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                        {grupos &&
                                            grupos.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.nombre}
                                                </MenuItem>
                                            ))}
                                    </SelectCustom>
                                    {errors.grupo && touched.grupo && (
                                        <FormHelperText>Campo Requerido</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/* Tipo Pruebas */}
                            <Grid item xs={12} sm={12} md={6} lg={3} sx={{ padding: "1em" }}>
                                <FormControl fullWidth error={errors.prueba}>
                                    <InputLabel id="input-label-inst">Tipos Pruebas*</InputLabel>
                                    <SelectCustom
                                        labelId="select-label-tipo-prueba"
                                        id="select-tipo-prueba"
                                        name="tipoPrueba"
                                        label="tipos pruebas*"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.tipoPrueba}
                                    >
                                        <MenuItem value="">Todos</MenuItem>
                                        {tipoPruebas &&
                                            tipoPruebas.map((item) => (
                                                <MenuItem key={item.key} value={item.key}>
                                                    {item.value}
                                                </MenuItem>
                                            ))}
                                    </SelectCustom>
                                    {errors.tipoPrueba && touched.tipoPrueba && (
                                        <FormHelperText>Campo Requerido</FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                            {/* Buscar */}
                            <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    sx={{ textTransform: "none", height: "3.5em" }}
                                    startIcon={<SearchTwoToneIcon />}
                                    onClick={() => {
                                        handleSubmit();
                                    }}
                                >
                                    Buscar
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                )}
            </Formik >
            <Divider />
            <Grid container spacing={2} style={{ marginTop: "0.1em" }}>
                <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                    <Container maxWidth="sm">
                        <Box sx={{ flexGrow: 1 }}>
                            <AppBar position="static">
                                <Toolbar>
                                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                        Estudiantes / Pruebas aplicadas
                                    </Typography>
                                </Toolbar>
                            </AppBar>
                        </Box>
                        <div style={{ marginTop: "1em", "overflow-y": "scroll", height: "700px" }}>
                            <TreeView
                                defaultCollapseIcon={<ExpandMoreIcon />}
                                defaultExpandIcon={<ChevronRightIcon />}
                            >
                                {evaluaciones.map(item => renderTreeItemEstudiante(item))}
                            </TreeView>
                        </div>

                    </Container>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={8} sx={{ padding: "1em" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <AppBar position="static">
                            <Toolbar>
                                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                                    <>
                                        {
                                            estudiante &&
                                            <>
                                                ({estudiante.codigo}) {estudiante.nombre}
                                            </>
                                        } / {prueba &&
                                            <>
                                                {`(${prueba.fecha}) ${prueba.nombre}`}
                                            </>
                                        }
                                    </>
                                </Typography>
                                <Button
                                    type="button"
                                    variant="contained"
                                    color="success"
                                    sx={{ textTransform: "none", height: "3.5em" }}
                                    startIcon={<SaveIcon />}
                                    onClick={() => {
                                        saveCalificaciones();
                                    }}
                                >
                                    Guardar calificaciones
                                </Button>
                            </Toolbar>
                        </AppBar>
                    </Box>
                    <Grid container spacing={1} columns={19} sx={{ margin: "0.2em", padding: "0em", "overflow-y": "scroll", height: "700px" }}>
                        <>
                            {prueba.componentes
                                && prueba.componentes.length > 0
                                && prueba.componentes.map((element, index) => (
                                    <>
                                        <Grid item xs={6} sm={6} md={6} lg={6} sx={{ margin: "0.2em", padding: "0em" }}>
                                            <Card>
                                                <CardContent>
                                                    <CalificarRenderPregunta
                                                        index={index}
                                                        nombre={element.nombre}
                                                        tipo={element.tipo}
                                                        respuesta={element.respuesta}
                                                        recurso={element.recurso}
                                                        handleTextFieldhange={handleTextFieldhange}
                                                        otros={{ ocultarConsec: true }}
                                                    />
                                                    {
                                                        (element.respuesta.length > 0) && (
                                                            <>
                                                                <Divider sx={{ marginTop: "4px" }} />
                                                                <Typography variant="subtitle2" component="div" sx={{ marginTop: "4px" }}>
                                                                    Calificación
                                                                </Typography >
                                                                <Box sx={{ paddingTop: 1 }}>
                                                                    <TextField
                                                                        id={element.id}
                                                                        name={element.id}
                                                                        variant="outlined"
                                                                        // value={respuestas[element.id] ?? ''}
                                                                        style={{ width: "100%" }}
                                                                        onChange={handleTextFieldhange(element.respuesta)}
                                                                    />
                                                                </Box>
                                                            </>
                                                        )
                                                    }
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    </>
                                ))
                            }
                        </>
                    </Grid>
                </Grid>
            </Grid >
        </>
    );
};

export default CalificarTestForm;
