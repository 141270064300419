import React, { useState, useEffect, useRef } from "react";
import { Alert, Grid } from "@mui/material";

const AudioPlayer = ({ url, validarEjecuciones }) => {
  const audioPlayer = useRef();
  const [playing, setPlaying] = useState(false);
  const [contPlay, setContPlay] = useState(0);
  const maxEjecuciones = 2;

  const play = () => {
    if (url) {
      setPlaying(true);
      audioPlayer.current.play();
    }
  };

  const stop = () => {
    if (url) {
      audioPlayer.current.pause();
      audioPlayer.current.currentTime = 0;
    }
  };

  const onPlaying = () => {
    if (audioPlayer.current.paused) {
      setPlaying(false);
      setContPlay(contPlay + 1);
    }
  };

  const inhabilitar = () => {
    if (validarEjecuciones) {
      if (contPlay === maxEjecuciones) return true;
    }
    return false;
  };

  return (
    <div
      onClick={!playing ? play : stop}
      disabled={inhabilitar()}
      style={{
        borderRadius: inhabilitar() ? "0.5em" : "",
        //padding: "0.5em",
        margin: inhabilitar() ? "1em" : "",
        border: inhabilitar() ? "solid #d32f2f" : "",
      }}
    >
      <Grid
        container
        component="main"
        sx={{
          display: "flex",
          borderRadius: 1,
        }}
      >
        <Grid item sm={12} md={5}>
          <audio
            src={url}
            controls
            ref={audioPlayer}
            onTimeUpdate={onPlaying}
          //id={"audio-" + keyOpcion}
          >
            Your browser does not support the
            <code>audio</code> element.
          </audio>
        </Grid>
        {validarEjecuciones && (
          <Grid item sm={12} md={2}>
            <h4>
              Ejecuciones {contPlay} / {maxEjecuciones}
            </h4>
          </Grid>
        )}
        {inhabilitar() && (
          <Grid item sm={12} md={5}>
            <Alert variant="outlined" severity="error">
              Llego al máximo de ejecuciones
            </Alert>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default AudioPlayer;
