import React, { useState, useEffect } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useNavigate, Outlet } from "react-router-dom";
import SideBarMenu from "layouts/SideBarMenu";
import imagenLogo from "assets/logo.png";
import Title from "components/Title";
import TestList from "modules/pruebas/Test/layouts/TestList";
import Icon from "@mui/material/Icon";
import cache from "data/cache";
var drawerWidth = null;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export default function PersistentDrawerLeft() {
  const [currentUser /*, setCurrentUser*/] = useState(
    cache.getCacheJSON("appUser")
  );
  const [environment /*, setEnvironment*/] = useState(
    cache.getCache("appEnvironment")
  );
  const [itemsList, setItemsList] = useState([]);
  const [itemsList2, setItemsList2] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleListItemClick = (index, route) => {
    setSelectedIndex(index);
    navigate("/dashboard" + route);
  };

  const handleClickHome = () => {
    navigate("/dashboard");
  };

  useEffect(() => {
    let data2 = [
      {
        modulo: "Pruebas",
        options: [
          {
            text: "Recursos asignados",
            route: "/list-test",
            rol: ["EST", "PAR"],
            icon: "quiz",
          },
          {
            text: "Habilitar",
            route: "/assign-test",
            rol: "ENC",
            icon: "assignment",
          },
          {
            text: "Resolver / inactivar",
            route: "/inactive-test",
            rol: "ENC",
            icon: "layers_clear",
          },
          {
            text: "Reporte",
            route: "/report-test",
            rol: "ENC",
            icon: "analytics",
          },
          {
            text: "Resultados",
            route: "/result-test",
            rol: "ENC",
            icon: "bar_chart",
          },
          {
            text: "Recursos",
            route: "/recursos-test",
            rol: "ENC",
            icon: "book",
          },
          {
            text: "Calificaciones",
            route: "/calificar-test",
            rol: "ENC",
            icon: "fact_check",
          },
          {
            text: "Predicción de Riesgo",
            route: "/prediction",
            rol: "ENC",
            icon: "bookmark_added",
          },
        ],
      },
      {
        modulo: "Evaluaciones",
        options: [
          {
            text: "Asignar",
            route: "/assign-survey",
            rol: ["ENC", "PAR"],
            icon: "assignment_ind",
          },
          /*{
            text: "Inactivar",
            route: "/inactive-survey",
            rol: "ENC",
            icon: "closed_caption_disabled",
          },
          {
            text: "Resultados",
            route: "/result-survey",
            rol: "ENC",
            icon: "analytics",
          },*/
        ],
      },
      {
        modulo: "Estudiantes",
        options: [
          {
            text: "Registrar",
            route: "/estudiantes-registrar-datos",
            rol: ["ENC"],
            icon: "contact_page",
          },
          {
            text: "Actualizar",
            route: "/estudiantes-actualizar-datos",
            rol: ["ENC"],
            icon: "contact_page",
          },
          /*{
            text: "Resultados",
            route: "/result-survey",
            rol: "ENC",
            icon: "analytics",
          },*/
        ],
      },
      {
        modulo: "Parametros",
        options: [
          {
            text: "Usuarios",
            route: "/users",
            rol: ["ENC"],
            icon: "contact_page",
          },
          {
            text: "Colegios",
            route: "/colegios",
            rol: ["ENC"],
            icon: "school",
          },
        ],
      },
    ];
    let data = [
      {
        text: "Recursos asignados",
        route: "/list-test",
        rol: ["EST", "PAR"],
      },
      //
      {
        text: "Habilitar",
        route: "/assign-test",
        rol: "ENC",
        icon: "assignment",
      },
      {
        text: "Resolver / inactivar",
        route: "/inactive-test",
        rol: "ENC",
        icon: "layers_clear",
      },
      {
        text: "Resultados",
        route: "/result-test",
        rol: "ENC",
        icon: "analytics",
      },
      {
        text: "Asignar",
        route: "/assign-survey",
        rol: "ENC",
        icon: "assignment_ind",
      },
      {
        text: "Inactivar",
        route: "/inactive-survey",
        rol: "ENC",
        icon: "closed_caption_disabled",
      },
      {
        text: "Resultados",
        route: "/result-survey",
        rol: "ENC",
        icon: "analytics",
      },
    ];
    setItemsList(
      data.filter((item) => item.rol.includes(currentUser.rol.abreviatura))
    );
    setItemsList2(data2);
    if (currentUser.rol.descripcion !== "Estudiante") {
      drawerWidth = 230;
    }
  }, []);

  return (
    <>
      <CssBaseline />
      <Title title="Dashboard - Home" />
      <Box
        sx={{
          display: "flex",
        }}
      >
        <AppBar position="fixed" open={open} sx={{ zIndex: 1000 }}>
          <Toolbar
            sx={{
              display: "flex",
              direction: "columns",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Sistema PROLESC
              {environment !== "Produccion" ? " - " + environment : null}
            </Typography>
            <SideBarMenu />
          </Toolbar>
        </AppBar>
        {currentUser.rol.descripcion !== "Estudiante" ? (
          <>
            <Drawer
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                "& .MuiDrawer-paper": {
                  width: drawerWidth,
                  boxSizing: "border-box",
                },
                zIndex: 1000,
              }}
              variant="persistent"
              anchor="left"
              open={open}
            >
              <DrawerHeader sx={{ display: "flex" }}>
                <Grid
                  container
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    bgcolor: "background.paper",
                    borderRadius: 1,
                  }}
                >
                  <Grid item xs={4} sx={{ pl: "10px" }}>
                    <Box
                      component="img"
                      sx={{
                        height: 50,
                        width: 50,
                        cursor: "pointer",
                      }}
                      alt="The house from the offer."
                      src={imagenLogo}
                      onClick={handleClickHome}
                    />
                  </Grid>
                  <Grid item xs={4} sx={{ alignSelf: "center" }}>
                    <Typography variant="h6" component="h1">
                      PROLESC
                    </Typography>
                  </Grid>
                  <Grid item xs={2} />
                  <Grid item xs={2}>
                    <IconButton onClick={handleDrawerClose}>
                      {theme.direction === "ltr" ? (
                        <ChevronLeftIcon />
                      ) : (
                        <ChevronRightIcon />
                      )}
                    </IconButton>
                  </Grid>
                </Grid>
              </DrawerHeader>
              <Divider />
              {itemsList2.length > 0 &&
                itemsList2.map((item, index) => (
                  <Accordion key={item.modulo} elevation={0}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>{item.modulo}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ pl: 1, pt: 0, pb: 0 }}>
                      {item.options.length > 0 &&
                        item.options
                          .filter((item) =>
                            item.rol.includes(currentUser.rol.abreviatura)
                          )
                          .map((item, index) => (
                            <List disablePadding={true}>
                              <ListItem key={item.text} disablePadding>
                                <ListItemButton
                                  onClick={() =>
                                    handleListItemClick(index, item.route)
                                  }
                                  selected={selectedIndex === index}
                                >
                                  <Icon sx={{ mr: "5px" }} color="primary">
                                    {item.icon}
                                  </Icon>
                                  <ListItemText primary={item.text} />
                                </ListItemButton>
                              </ListItem>
                            </List>
                          ))}
                    </AccordionDetails>
                  </Accordion>
                ))}
              <Divider />
              {/*itemsList.map((item, index) => (
            <List>
              <ListItem key={item.text} disablePadding>
                <ListItemButton
                  onClick={() => handleListItemClick(index, item.route)}
                  selected={selectedIndex === index}
                >
                  <Icon sx={{ mr: "10px" }} color="primary">
                    {item.icon}
                  </Icon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            </List>
          ))*/}
            </Drawer>
            <Box
              component="main"
              sx={{
                flexGrow: 1,
                //minHeight: "100vh",
                //overflow: "auto",
              }}
            >
              <Main open={open}>
                <DrawerHeader />
                {/*<ToolbarComp/>*/}
                <Outlet />
              </Main>
            </Box>
          </>
        ) : (
          <>
            <TestList />
          </>
        )}
      </Box>
      {/*currentUser.rol.descripcion !== "Estudiante" ? <></> : <TestList />*/}
    </>
  );
}
