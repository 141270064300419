import React, { useState, useEffect } from "react";
import { Box, Grid } from "@mui/material";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import QuickSearch from "components/QuickSearch";
import { showAlert, showAlertError, showAlertSuccess } from "utils/alert";
import { useParams, useNavigate, Navigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import paramsService from "services/params.services";

const initialValues = {
  codigo: "",
  nombre: "",
  direccion: "",
  celular: "",
  telefono: "",
  email: "",
  id_departamento: "",
  codigo_dane: "",
};
const formSchema = Yup.object().shape({
  codigo: Yup.string().required("Campo Requerido"),
  nombre: Yup.string().required("Campo Requerido"),
  direccion: Yup.string().required("Campo Requerido"),
  celular: Yup.string().required("Campo Requerido"),
  telefono: Yup.string().required("Campo Requerido"),
  email: Yup.string().required("Campo Requerido"),
  codigo_dane: Yup.string().required("Campo Requerido"),
});
const ColegioForm = ({ setOpenLoading }) => {
  const [departamentos, setDepartamentos] = useState([]);
  const [municipios, setMunicipios] = useState([]);
  const { id } = useParams();
  const clearForm = () => {
    //setSavedData(null);
  };
  async function getDepartaments() {
    try {
      setOpenLoading(true);
      var response = await paramsService.getDepartaments();
      if (response.status === 200) {
        setOpenLoading(false);
        setDepartamentos(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  const getMunicipios = async (departamento) => {
    let pruebas = departamentos.find((e) => e.id === departamento).municipios;
    setMunicipios(pruebas);
  };

  async function saveColegio(data) {
    var response = null;
    if (id) {
      response = await paramsService.updateSchools(data);
    } else {
      response = await paramsService.createSchools(data);
    }
  }
  const init = () => {
    getDepartaments();
  };
  useEffect(() => {
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Formik
        enableReinitialize={false}
        initialValues={initialValues}
        validate={(values) => {
          const errors = {};
          if (values.id_departamento) getMunicipios(values.id_departamento);
          return errors;
        }}
        validationSchema={formSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          setOpenLoading(true);
          saveColegio(values)
            .then(() => {
              setOpenLoading(false);
              showAlertSuccess("Colegio grabado correctamente");
              resetForm();
              Navigate("/dashboard/colegios");
            })
            .catch((error) => {
              let mensaje = "";
              if (error.response.data.mensaje) {
                mensaje = error.response.data.mensaje;
              } else {
                mensaje = 'Error al crear el colegio';
              }
              setOpenLoading(false);
              showAlertError(mensaje);
            });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
          isLoading,
          /* and other goodies */
        }) => (
          <Box component="form-Login" noValidate sx={{ mt: 1 }}>
            {/* Toolbar */}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                bgcolor: "background.paper",
              }}
            >
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="cleaning_services"
                  description="Limpiar"
                  sx={{ mt: "1.5em" }}
                  color="primary"
                  onClick={() => {
                    resetForm();
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <ButtonIcon
                  id="btnSubmit"
                  fullWidth
                  iconname="save"
                  description="Guardar"
                  sx={{ mt: "1.5em" }}
                  color="success"
                  onClick={handleSubmit}
                />
              </Grid>
            </Grid>
            {/* Page*/}
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                borderRadius: 1,
              }}
            >
              {/* codigo */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  autoFocus={true}
                  name="codigo"
                  label="Codigo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.codigo}
                  value={values.codigo || ""}
                  error={errors.codigo}
                />
              </Grid>
              {/* nombre */}
              <Grid item xs={12} sm={12} md={6} lg={6} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="nombre"
                  label="Nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.nombre}
                  value={values.nombre || ""}
                  error={errors.nombre}
                />
              </Grid>
              {/* direccion */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="direccion"
                  label="Dirección"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.direccion}
                  value={values.direccion || ""}
                  error={errors.direccion}
                />
              </Grid>
              {/* celular */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="celular"
                  label="Celular"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.celular}
                  value={values.celular || ""}
                  error={errors.celular}
                />
              </Grid>
              {/* telefono */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="telefono"
                  label="Telefono"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.telefono}
                  value={values.telefono || ""}
                  error={errors.telefono}
                />
              </Grid>
              {/* email */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="email"
                  label="Correo"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.email}
                  value={values.email || ""}
                  error={errors.email}
                />
              </Grid>
              {/* id_departamento */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="id_departamento"
                  label="Departamentos"
                  keyItem="id"
                  valueItem="nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.id_departamento}
                  error={errors.id_departamento}
                  touched={touched.id_departamento}
                  isQuickSearch={false}
                  data={departamentos}
                />
              </Grid>
              {/* codigo_dane */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                sx={{ padding: "1em" }}
              >
                <QuickSearch
                  name="codigo_dane"
                  label="Municipios*"
                  keyItem="id"
                  valueItem="nombre"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.codigo_dane}
                  error={errors.codigo_dane}
                  touched={touched.codigo_dane}
                  isQuickSearch={false}
                  data={municipios}
                />
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default ColegioForm;
