import React, { useState } from "react";
import { Paper } from "@mui/material";
import HeaderPage from "components/HeaderPage";
import Loading from "components/Loading";
import Title from "components/Title";
import RecursosTestForm from "../components/PredictionForm";

const Prediction = () => {
  const [openLoading, setOpenLoading] = useState(false);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Dashboard - Recursos de Pruebas" />
      <HeaderPage descripcion={"Predicción de Riesgo"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        <RecursosTestForm setOpenLoading={setOpenLoading} />
      </Paper>
    </>
  );
};

export default Prediction;
