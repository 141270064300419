import React, { useState, useEffect } from "react";
import { Grid, Box, Paper, Stack, IconButton, Icon, FormLabel, FormControl, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import Loading from "components/Loading";
import ButtonIcon from "components/ui/Buttons/ButtonIcon";
import TableDataGrid from "components/ui/Tables/TableDataGrid";
import {
  showAlert,
  showAlertConfirm,
  showAlertError,
  showAlertSuccess,
} from "utils/alert";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import userService from "services/user.services";
import HeaderPage from "components/HeaderPage";
import Title from "components/Title";
import paramsService from "services/params.services";
import QuickSearch from "components/QuickSearch";
const headers = [
  {
    field: "id",
    headerName: "id",
    //description: 'This column has a value getter and is not sortable.',
    sortable: false,
    //width: 100,
    // hide: true,
    flex: 1,
  },
  {
    field: "nombre",
    headerName: "Nombre",
    //description: 'This column has a value getter and is not sortable.',
    flex: 2,
    minWidth: 100,
  }
];

const initialValues = {
  colegio: "",
  grado: "",
  grupo: ""
};

const formSchema = Yup.object().shape({
  colegio: Yup.string().required("Campo Requerido"),
  grado: Yup.string().required("Campo Requerido"),
  grupo: Yup.string().required("Campo Requerido")
});

const ListColegios = () => {
  const [openLoading, setOpenLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [usersFilters, setUsersFilters] = useState([]);
  const [columns, setColumns] = useState(headers);
  const [colegios, setColegios] = useState([]);
  const [grados, setGrados] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [filter, setFilter] = useState("todos");
  const theme = useTheme();
  const navigate = useNavigate();
  const getUsers = async () => {
    try {
      setOpenLoading(true);
      var response = await userService.getUsers();
      if (response.status === 200) {
        if (response.data.data.length > 0) {
          setUsers(response.data.data);
          setUsersFilters(response.data.data);
        }
        setOpenLoading(false);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
      setOpenLoading(false);
    }
  };
  const changeStatusUser = async (id, estado) => {
    const data = {
      usuario: id,
      estado,
    };
    try {
      setOpenLoading(true);
      var response = await userService.changeStatusUser(data);
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("Usuario " + estado === 1 ? "activado" : "inactivado");
      }
    } catch (error) {
      console.log(error);
      setOpenLoading(false);
      showAlertError("Error al inactivar el usuario!");
    }
  };
  async function getColegios() {
    try {
      setOpenLoading(true);
      var response = await paramsService.getSchools();
      if (response.status === 200) {
        setOpenLoading(false);
        setColegios(response.data.data);
      }
    } catch (error) {
      showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');
      setOpenLoading(true);
    }
  }
  async function getGrados(data) {
    try {
      var response = await paramsService.getGrades(data);
      if (response.status === 200) {
        setGrados(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  async function getGrupos(data) {
    try {
      var response = await paramsService.getClassRoom(data);
      if (response.status === 200) {
        setGrupos(response.data.data);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  }
  const handleClickFilter = (event, resetForm) => {
    setFilter(event.target.value);
    // clearForm();
    resetForm();
    // setAsignarComponente(false);
  };
  const setHeaders = () => {
    let array = [...headers];
    let actions = [
      {
        field: "ver",
        headerName: "",
        flex: 0.1,
        sortable: false,
        renderCell: (cellValues) => (
          <>
            <Stack direction="row" spacing={1}>
              <IconButton
                aria-label="ver"
                onClick={() => navigate("/dashboard/editar-colegios/" + cellValues.row.id)}>
                <Icon color="info">visibility</Icon>
              </IconButton>
            </Stack>
          </>
        ),
        disableClickEventBubbling: true,
      },
      {
        field: "changeStatus",
        headerName: "",
        flex: 0.1,
        sortable: false,
        renderCell: (cellValues) => (
          <>
            <Stack direction="row" spacing={1}>
              <IconButton
                aria-label="toggle_off"
                onClick={() => {
                  showAlertConfirm(
                    "warning",
                    "Eliminar Colegio",
                    "¿Desea eliminarlo?",
                    async function (confirmed) {
                      if (confirmed) {
                        const status = cellValues.row.estado === 1 ? 0 : 1;
                        await changeStatusUser(cellValues.row.id, status).then(
                          () => {
                            init();
                          }
                        );
                      }
                    }
                  );
                }}
              >
                <Icon color="info">delete</Icon>
              </IconButton>
            </Stack>
          </>
        ),
        disableClickEventBubbling: true,
      },
    ];
    actions.forEach((elements) => {
      array.push(elements);
    });
    setColumns(array);
  };
  const init = () => {
    getColegios();
    // setUsers([]);
    // getUsers(users);
  };
  useEffect(() => {
    setHeaders();
    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (filter === "todos") {
      setUsersFilters(users);
    }
  }, [filter]);
  return (
    <>
      <Loading openLoading={openLoading} />
      <Title title="Dashboard - Colegios" />
      <HeaderPage descripcion={"Colegios"} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          minHeight: "86vh",
          //minWidth: "85vh",
        }}
      >
        {/* Toolbar */}
        <Grid
          container
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            bgcolor: "background.paper",
            background: theme.palette.secondary.light,
            padding: "0,5em",
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
            <ButtonIcon
              id="btnSubmit"
              fullWidth
              iconname="add_circle"
              description="Agregar"
              color="primary"
              onClick={() => navigate("/dashboard/crear-colegios")}
            />
          </Grid>
        </Grid>
        {/* Page */}
        <Formik
          initialValues={initialValues}
          validationSchema={formSchema}
          validate={(values) => {
            const errors = {};
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {

          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            resetForm,
            setFieldValue,
            /* and other goodies */
          }) => (
            <Box
              component="form"
              noValidate
              id="asignar-pruebas-form"
              sx={{ mt: 1 }}
            >
              <Grid
                container
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  padding: "1em",
                }}
              >
                {/* Usuarios */}
                {colegios.length > 0 && (
                  <Grid item xs={12}>
                    <TableDataGrid
                      tittle={"Listado de colegios"}
                      data={colegios}
                      columns={columns}
                    />
                  </Grid>
                )}
              </Grid>
            </Box>
          )}
        </Formik>
      </Paper>
    </>
  );
};

export default ListColegios;
