import React, { useState, useEffect } from "react";
import {
  Grid,
  FormHelperText,
  InputLabel,
  FormControl,
  Box,
  MenuItem,
  Button,
  Container,
  Typography,
  AppBar,
  Toolbar,
} from "@mui/material";
import SearchTwoToneIcon from "@mui/icons-material/SearchTwoTone";
import { TreeView } from '@mui/x-tree-view/TreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { Formik } from "formik";
import testService from "services/test.services";
import estudianteService from "services/estudiantes.services";
import moment from "moment";
import SelectCustom from "components/ui/Selects/SelectCustom";
import { showAlertError, showAlertInfo, showAlertSuccess } from "utils/alert";
import { config } from "utils/config";
// Icons
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TextFieldCustom from "components/ui/TextFields/TextFieldCustom";
import { datos } from "data/datos";

const initialValues = {
  fechaInicio: moment(),
  fechaFin: moment(),
  colegio: "",
  grado: "",
  grupo: "",
  tipoPrueba: "",
  prueba: "",
  codEstudiante: "",
};

const PredictionForm = ({ setOpenLoading }) => {
  const [tipoPruebas, setTipoPruebas] = useState([]);
  const [pruebasRecursos, setPruebasRecursos] = useState([]);
  const [pruebas, setPruebas] = useState([]);
  const [recursoIframe, setRecursoIframe] = useState(null);

  const server = config.API_RESOURCE_URL;

  async function getTipoPruebas() {
    try {
      setOpenLoading(true);
      var response = await testService.getTestType({ ocultar: 1 });
      if (response.status === 200) {
        setTipoPruebas(response.data.data);
        setOpenLoading(false);
      }
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
      setOpenLoading(false);
    }
  }
  const getPruebas = async (tipoPrueba) => {
    try {
      setPruebas(tipoPruebas.find((e) => e.key === tipoPrueba).pruebas);
    } catch (error) {
      /*showAlert('error',
        '!Ha ocurrido un error!',
        'Al cargar los colegios');*/
    }
  };
  async function getRecursosPruebas(data, resetForm) {
    try {
      var response = await testService.recursos_GetRecursosByPrueba(data);
      if (response.status === 200) {
        setOpenLoading(false);
        setPruebasRecursos(response.data.data);
      }
    } catch (error) {
      setOpenLoading(false);
      setPruebasRecursos([]);
      setRecursoIframe(null);
      showAlertError("No se encontraron datos");
    }
  }
  async function getRiesgo(data) {
    try {
      var response = await estudianteService.obtenerRiesgo(data);
      console.log(response);
      if (response.status === 200) {
        setOpenLoading(false);
        showAlertSuccess("El riesgo calculado es de: " + response.data.data.riesg_resum);
      }
    } catch (error) {
      setOpenLoading(false);
      showAlertError("El estudiante no tiene riesgo calculado");
    }
  }
  const handleItemClick = (recurso) => {
    setRecursoIframe(
      `https://docs.google.com/viewer?url=${server}${recurso}&embedded=true`
    );
  };
  const renderTreeItemEstudiante = (item) => {
    const renderTreeItemPruebaWithItem = (child) => {
      return renderTreeItemPrueba(item, child);
    };

    return (
      <TreeItem
        key={item.key}
        nodeId={item.key}
        label={item.value} >
        {item.pruebas && item.pruebas.map(child => renderTreeItemPrueba(item, child))}
      </TreeItem>
    );
  };
  const renderTreeItemPrueba = (estudiante, prueba) => (
    <TreeItem
      nodeId={prueba.key}
      label={prueba.value}
      onClick={() => handleItemClick(prueba.recurso)} />
  );

  const initForm = () => {
    getTipoPruebas();
  };

  useEffect(() => {
    initForm();
  }, []);
  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          getRiesgo({
            estudiante: values.codEstudiante,
          });
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
          setFieldValue,
        }) => (
          <Box
            component="form"
            noValidate
            id="resutado-pruebas-form"
            sx={{ mt: 1 }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                bgcolor: "background.paper",
                borderRadius: 1,
              }}
            >
              {/* Tipo Pruebas */}
              <Grid item xs={12} sm={12} md={6} lg={4} sx={{ padding: "1em" }}>
                <TextFieldCustom
                  name="codEstudiante"
                  label="Código Estudiante"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={String(touched.codEstudiante)}
                  value={values.codEstudiante || ""}
                  error={errors.codEstudiante}
                />
              </Grid>
              {/* Buscar */}
              <Grid item xs={12} sm={12} md={6} lg={2} sx={{ padding: "1em" }}>
                <Button
                  type="button"
                  fullWidth
                  variant="contained"
                  sx={{ textTransform: "none", height: "3.5em" }}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Ejecutar
                </Button>
              </Grid>
            </Grid>
          </Box>
        )}
      </Formik>
    </>
  );
};

export default PredictionForm;
