import React, { useState, useEffect } from "react";
import { Paper } from "@mui/material";
import Loading from "components/Loading";
import UserForm from "../components/ColegioForm";
import ColegioForm from "../components/ColegioForm";

const CreateColegio = () => {
  const [openLoading, setOpenLoading] = useState(false);  
  return (
    <>
      <Loading openLoading={openLoading} />
      <Paper
        elevation={5}
        sx={{
          margin: "1em",
          padding: "1em",
          borderRadius: 2,
        }}
      >
        <ColegioForm
          setOpenLoading={setOpenLoading}
        />
      </Paper>
    </>
  );
};

export default CreateColegio;
